<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Update Specific Data</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.value.val" :description="form.value.inv" label="Data" />
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary" class="mr-2"> Update</CButton>
                            <CButton color="dark" @click="go_back">Back</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                value: {val: '', inv: '', v: { req: true, max: 1024 }},
            },
            disable: false,
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get(){
           this.axios.get('arbitrage/system_custom_values/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    for(const key in res.data[0]){
                        for(const k in this.form){
                            if(key == k){
                                this.form[k].val = res.data[0][key];
                            }
                        }
                    }
                }) 
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
                for (const key in this.form){
                    data.set(key, this.form[key].val);
                }
                this.axios.put('arbitrage/system_custom_values/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Updated Successfully.'
                        });
                        this.$router.push('/data/custom');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
        go_back(){
            this.$router.go(-1);
        }
    }
}
</script>
